import { colors } from '../styles/variables';

const { light, dark, grey300 } = colors;


export const Tolgay = ({ name = 'A. Tolgay Yılmaz' }) => (
  <img alt={name} src="https://i.imgur.com/m3uNO25.jpg" width={"220px"} style={{borderRadius: "2em"}}></img>
);

export const Evrim = ({ name = 'Evrim Polat Aksu' }) => (
  <img src="https://i.imgur.com/JJnGHv9.png" width={'220px'} style={{borderRadius: "2em"}} ></img>
);

export const Robert = ({ name = 'Robert M. Stanley' }) => (
  <img src="https://i.imgur.com/xXnsjIu.jpg" width={'220px'} style={{borderRadius: "2em"}} ></img>
);

export const Yasar = ({ name = 'Yaşar Kantarcılar' }) => (
  <img src="https://i.imgur.com/0DLoVvW.png" width={'220px'} style={{borderRadius: "2em"}} ></img>
);


