import Price from './design/price';

function Pricing(props) {
  const { id, comicTheme = false } = props;

  return (
    <Price
      id={id}
      title="What about pricing?"
      description="money is not an issue here."
      blink="Being part of a bridge culture is a priceless experience we want to share."
      comicTheme={comicTheme}
    />
  );
}

export default Pricing;
