import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import Hero from './design/hero';

const CoinWrapper = styled.div``;

function Intro({ isVisible }) {
  return (
    <InView threshold={0.14} onChange={(inView) => isVisible(inView)}>
      {({ inView, ref }) => (
        <CoinWrapper ref={ref} inView={inView}>
          <Hero
            getStartedUrl="https://app.newnew.media"
            // docsUrl="https://statefulart.substack.com/"
            // deckUrl="https://docs.google.com/presentation/d/11OFnPbo44LMuxCp3kCRhHr2A33-UZ27mZbqWkx66DEE/edit?usp=sharing"
          >
            <h1>
              <span>Discover artists and venues </span>
              <br /> <span>like you've never done before.</span>
            </h1>
            <span>
              <strong>new new media</strong> is a location-aware, community-enabled art discovery
              and streaming platform.
            </span>
          </Hero>
        </CoinWrapper>
      )}
    </InView>
  );
}

export default Intro;
