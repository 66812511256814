import { useState } from 'react';
import Tabs from './design/tabs';
import { ExampleNfts, ExampleCryptoTokens, ExampleDefi, ExampleDaos } from '../utils/examples';
import { nft, crypto, defi, dao } from '../utils/snippets';
import { colors } from '../styles/variables';
import ButtonLink from './design/button-link';

const { orange, green500 } = colors;

function Examples({ comicTheme = false }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Audience',
      title: 'Become an art gourmet',
      subtitle: 'Let your taste in arts show you the way',
      content:
        'Discover artists through venues and recommend new places that resonate your taste in art.',
      url: 'https://docs.dappkit.dev/start-building/how-to-guides/create-an-nft',
      snippet: nft,
      img: <ExampleNfts mainColor={comicTheme ? orange : green500} />,
    },
    {
      id: 1,
      name: 'Artists',
      title: 'Drop your artwork',
      subtitle: 'Find new avenues of discovery & exposure',
      content:
        'Offer your artworks to venues, have your stake in the subscription revenues they make and engage new audience.  ',
      url: 'https://docs.dappkit.dev/start-building/how-to-guides/create-an-erc-20-token',
      snippet: crypto,
      img: <ExampleCryptoTokens mainColor={comicTheme ? orange : green500} />,
    },
    {
      id: 2,
      name: 'Venues',
      title: 'Have an artistic presence',
      subtitle: 'Keep Earning 24/7',
      content:
        'Display your music library or embed your playlists on Spotify. Create communities and subscriptions for your private content and earn @ your off-hours. ',
      url: 'https://docs.dappkit.dev/start-building/how-to-guides/usage',
      snippet: defi,
      img: <ExampleDefi mainColor={comicTheme ? orange : green500} />,
    },
    // {
    //   id: 3,
    //   name: 'DAOs',
    //   title: 'Decentralised Autonomous Organisations',
    //   subtitle: 'Vote contract and network contract',
    //   content: 'Base your DAO on our Network and take advantage of a community made smart contract',
    //   url: 'https://docs.dappkit.dev/start-building/how-to-guides/usage',
    //   snippet: dao,
    //   img: <ExampleDaos mainColor={comicTheme ? orange : green500} />,
    // },
  ];

  return (
    <>
      <Tabs
        tabs={tabs}
        content={tabs[selectedTab]}
        onClick={(index) => {
          setSelectedTab(index);
        }}
      />
      <ButtonLink color="dark" url={'https://app.newnew.media'} value="Open app" />
    </>
  );
}

export default Examples;
