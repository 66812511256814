import styled from 'styled-components';
import { rem } from 'polished';
import Section from './design/section';
import CardLink from './design/card-link';
import { sizes } from '../styles/variables';
import {
  Nodejs,
  Alchemy,
  CeramicNetwork,
  Supabase,
  OrbisProtocol,
  UnlockProtocol,
  GooglePlaces,
  Mapbox,
  Quicknode,
} from '../utils/brands';

const { defaultSize } = sizes;

const Grid = styled.ul`
  margin: ${rem('-25px', defaultSize)} 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  li {
    list-style: none;
    margin: ${rem('25px', defaultSize)};
  }
`;

function Templates({ id }) {
  const templates = [
    {
      name: 'Orbis Protocol',
      url: 'https://useorbis.com',
      img: <OrbisProtocol />,
    },

    {
      name: 'Ceramic Network',
      url: 'https://ceramic.network/',
      img: <CeramicNetwork />,
    },
    {
      name: 'Alchemy',
      url: 'https://www.alchemy.com/',
      img: <Alchemy />,
    },
    {
      name: 'Quicknode',
      url: 'https://www.quicknode.com',
      img: <Quicknode />,
    },
    {
      name: 'Unlock Protocol',
      url: 'https://unlock-protocol.com',
      img: <UnlockProtocol />,
    },
    {
      name: 'Supabase',
      url: 'https://supabase.com',
      img: <Supabase />,
    },
    {
      name: 'Mapbox',
      url: 'https://mapbox.com/',
      img: <Mapbox />,
    },
    {
      name: 'Google Places',
      url: 'https://developers.google.com/maps/documentation/places/web-service/overview',
      img: <GooglePlaces />,
    },

    // {
    //   name: 'Spotify',
    //   url: 'https://spotify.com',
    //   img: <Spotify />,
    // },
    // {
    //   name: 'ReactJS',
    //   url: 'https://reactjs.org',
    //   img: <ReactJs />,
    // },
    {
      name: 'NodeJS',
      url: 'https://nodejs.org',
      img: <Nodejs />,
    },

    // {
    //   name: 'Elixir',
    //   url: 'https://elixir-lang.org',
    //   img: <Elixir />,
    // },
  ];
  return (
    <Section id={id} title="Our tech-stack " subtitle="We are creating our solutions with:">
      <Grid>
        {templates &&
          Array.isArray(templates) &&
          templates.map((template, index) => (
            <li key={index}>
              <CardLink img={template.img} url={template.url} />
            </li>
          ))}
      </Grid>
    </Section>
  );
}

export default Templates;
