import React from 'react';
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { main, comic } from './styles/design-tokens';
import GlobalStyle from './styles/global-style';
import NavMenu from './components/nav-menu';
import Intro from './components/intro';
import Examples from './components/examples';
import Features from './components/features';
import Templates from './components/templates';
import Bounties from './components/bounties';
import Pricing from './components/pricing';
import Projects from './components/projects';
import Team from './components/team'
import Footer from './components/footer';
import KonamiTrigger from './components/konami';

function App() {
  const [heroIsVisible, setHeroIsVisible] = useState(true);
  const [comicTheme, setComicTheme] = useState(false);

  return (
    <ThemeProvider theme={comicTheme ? comic : main}>

      <GlobalStyle comicTheme={comicTheme} />
      <KonamiTrigger
        easterEggIsActive={(easterEggIsActive) => {
          setComicTheme(easterEggIsActive);
        }}
      />
      <NavMenu heroIsVisible={heroIsVisible} comicTheme={comicTheme} />
      <Intro isVisible={(isVisible) => setHeroIsVisible(isVisible)} />
      <Examples comicTheme={comicTheme} />
      <Features id="features" />
      <Projects id="places" />
      <Bounties comicTheme={comicTheme} />
      <Pricing id="pricing" comicTheme={comicTheme} />
      <Templates id="technology" />
      <Team id="team" />
      <Footer comicTheme={comicTheme} />
    </ThemeProvider>
  );
}

export default App;
