import styled from 'styled-components';
import { rem } from 'polished';
import Section from './design/section';
import ButtonLink from './design/button-link';
import {
  FactoryBerlin,
  LeylaCoffee,
  WistromsPub,
  CafeBabalu,
  SixNations,
  Knust,
  Teru,
  Odin,
  ColesCorner,
  AssemblySeattle,
  TheArtRoom,
} from '../utils/brands';
import { sizes, colors } from '../styles/variables';

const { defaultSize } = sizes;
const { purple500 } = colors;

const Grid = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    width: ${rem('300px', defaultSize)};
    list-style: none;
    padding: ${rem('30px', defaultSize)};

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
      max-width: ${rem('250px', defaultSize)};
      max-height: ${rem('50px', defaultSize)};
    }
  }

  & + a {
    display: inline-block;
    margin: ${rem('60px', defaultSize)} 0 0 50%;
    transform: translateX(-50%);
    background-color: ${purple500};

    span {
      border: 0;
    }
  }
`;

function Projects({ id }) {
  const projects = [
    { url: 'https://knusthamburg.de', img: <Knust /> },
    { url: 'https://factoryberlin.com/', img: <FactoryBerlin /> },
    { url: 'https://www.artroomdtla.com', img: <TheArtRoom /> },

    { url: 'https://www.instagram.com/leylacof/', img: <LeylaCoffee /> },
    { url: 'https://colescorner.co.uk/', img: <ColesCorner /> },
    { url: 'https://www.assemblyseattle.com', img: <AssemblySeattle /> },
    { url: 'https://www.instagram.com/odinpub.antalya/', img: <Odin /> },
    { url: 'https://cafe-babalu.github.io/cafe-babalu/', img: <CafeBabalu /> },
    { url: 'https://www.instagram.com/six.nations.pub/', img: <SixNations /> },
    { url: 'https://wirstromspub.se/', img: <WistromsPub /> }  ];

  return (
    <Section id={id} color="dark" title="Some venues with their artistic presence">
      <p
        style={{ marginTop: '1rem', marginLeft: '2rem', marginRight: '2rem', textAlign: 'center', marginBottom: "2rem" }}
      >
        Dear art gourmettes in our community already introduced 50+ venues. Some artists already dropped their artworks on those venues'
        libraries as media uploads and NFT listings.
      </p>
      <Grid>
        {projects &&
          Array.isArray(projects) &&
          projects.map((project, index) => (
            <li key={index}>
              <a href={project.url} target="_blank" rel="noopener noreferrer">
                {project.img}
              </a>
            </li>
          ))}
      </Grid>

      <ButtonLink color="grey" url={'https://app.newnew.media'} value="Open app" />

      {/* <ButtonLink url="#0" value="Case Studies" /> */}
    </Section>
  );
}

export default Projects;
