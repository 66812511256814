import styled from 'styled-components';
import { rem } from 'polished';
import Section from './design/section';
import Card from './design/card';
import { sizes, device } from '../styles/variables';

const { defaultSize } = sizes;

const Grid = styled.div`
  margin: 0 ${rem('-30px', defaultSize)};
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${rem('30px', defaultSize)};
  overflow-x: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-snap-type: x mandatory;
  scroll-padding-left: ${rem('60px', defaultSize)};

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    content: '';
    width: ${rem('15px', defaultSize)};
  }

  > div {
    width: calc(100vw - ${rem('120px', defaultSize)});
    scroll-snap-align: start;
  }

  @media ${device.s} {
    > div {
      width: calc(100vw - ${rem('300px', defaultSize)});
    }
  }

  @media ${device.m} {
    margin: 0;
    display: grid;
    grid-auto-flow: inherit;
    grid-template-columns: repeat(12, 1fr);
    overflow: inherit;

    &:before,
    &:after {
      content: inherit;
      width: 0;
    }

    > div {
      width: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        grid-column: 1 / 6;
        grid-row: 1 / 2;
      }

      &:nth-child(2) {
        grid-column: 6 / 13;
        grid-row: 1 / 2;
      }

      &:nth-child(3) {
        grid-column: 1 / 8;
        grid-row: 2 / 3;
      }

      &:nth-child(4) {
        grid-column: 8 / 13;
        grid-row: 2 / 3;
      }

      &:nth-child(5) {
        grid-column: 1 / 5;
        grid-row: 3 / 4;
      }

      &:last-child {
        grid-column: 5 / 13;
        grid-row: 3 / 4;
      }
    }
  }
`;

function Features({ id }) {
  const items = [
    {
      title: 'Discover & introduce new places',
      description: [
        'Hey there, art gourmet! Let us know venues that have impressed you with their artistic presence. Say it is a cafe, bar, restaurant or whatnot.',
      ],
    },
    {
      title: 'Offer your artwork',
      description: [
        "Indie artists! Offer your music to venues' libraries. Establish fair revenue streams from potentially thousands of sources instead of a few.",
      ],
    },
    // {
    //   title: 'Start web3 communities',
    //   description: [
    //     'Initiate a digital artistic community for your place. Whether you are operating a cafe, bar, restaurant, art gallery or barber shop. This is where your place is open 24/7.',
    //   ],
    // },
   
    {
      title: 'Build an artistic presence',
      description: [
        'Venues! Get ready for creating cooperations with artists and develop membership/loyalty programs for your visitors with exclusive content. Show your artistic presence.',

        // <u>
        //   <span key={1}>
        //     {' '}
        //     <a
        //       style={{ all: 'unset' }}
        //       href="https://unlock-protocol.com"
        //       rel="noreferrer"
        //       target={'_blank'}
        //     >
        //       Unlock Protocol.
        //     </a>
        //   </span>
        // </u>,

  
      ],
    },
    {
      title: 'A fusion of current and the new ',
      description: [
        'Join with an email/password or a decentralized ID/DID  we create for you on Ceramic Network if you connect with a metamask.',
      ],
    },

    // {
    //   title: 'Discover venues through art',
    //   description:
    //     "Let your taste in arts show you the way & discover new venues with their soulfood as well. Imagine you're looking for a place with 'rock music' instead of 'vegetarian food'.",
    // },
  ];

  return (
    <Section id={id} color="dark" title="What are we doing here?">
      <Grid>
        {items &&
          Array.isArray(items) &&
          items.map((item, index) => (
            <Card key={index} color="dark" title={item.title} description={item.description} />
          ))}
      </Grid>
    </Section>
  );
}

export default Features;
