import ButtonLink from '../button-link';
import * as Styles from './styles';

function Hero(props) {
  const { getStartedUrl, children } = props;

  return (
    <Styles.Wrapper>
      {children}
      <Styles.ActionButtons>
        <ButtonLink color="dark" url={getStartedUrl} value="Get Started"  />
        {/* <ButtonLink url={docsUrl} value="Blog" external /> */}
        {/* <ButtonLink url={deckUrl} value="Read our deck" external /> */}

      </Styles.ActionButtons>
    </Styles.Wrapper>
  );
}

export default Hero;
