import styled from 'styled-components';
import { rem } from 'polished';
import Section from './design/section';
import { sizes } from '../styles/variables';
import { Tolgay, Evrim, Robert, Yasar } from '../utils/team-members';

const { defaultSize } = sizes;

const Grid = styled.ul`
  margin: ${rem('-25px', defaultSize)} 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  li {
    list-style: none;
    margin: ${rem('25px', defaultSize)};
  }
`;

function Team({ id }) {
  const mates = [
    {
      name: 'A. Tolgay Yılmaz',
      url: 'https://www.linkedin.com/in/abbas-tolgay-yilmaz-0x0/',
      img: <Tolgay />,
      role: 'Product Manager',
    },

    {
      name: 'Evrim Polat Aksu',
      url: 'https://www.linkedin.com/in/evrim-aksu-0b5766110/',
      img: <Evrim />,
      role: 'Design & Branding',
    },

    {
      name: 'Rob M. Stanley',
      url: 'https://www.linkedin.com/in/robertmathewstanley/',
      img: <Robert />,
      role: 'Strategy & Growth',
    },

    {
      name: 'Yaşar Kantarcılar',
      url: 'https://www.linkedin.com/in/ya%C5%9Far-kantarcilar-646117244/',
      img: <Yasar />,
      role: 'Fullstack developer',
    },
  ].sort(() => Math.random() - 0.5);
  return (
    <Section id={id} title="Our team " subtitle="We are cool.">
      <Grid>
        {mates &&
          Array.isArray(mates) &&
          mates.map((member, index) => (
            <li key={index}>
              <div
                style={{ borderRadius: '2rem', display: 'flex', flexDirection: 'column' }}
                width="364px"
                height="364px"
              >
                <a href={member.url} target="_blank" rel="noreferrer">
                  {member.img}
                </a>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p
                      style={{
                        textAlign: 'center',
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '0.4rem',
                        flex: '1'
                      }}
                    >
                      {member.name}
                    </p>
                    <a style={{flex: "0.2", paddingTop: "10px"}} href={member.url} target="_blank" rel="noreferrer">
                    <img width="28rem" src="../../images/linkedin-icon.svg" ></img>

                    </a>
                  </div>
                  <p
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '0.4rem',
                    }}
                  >
                    {member.role}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </Grid>
    </Section>
  );
}

export default Team;
