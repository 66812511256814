import styled from 'styled-components';
import { rem } from 'polished';
import Section from './design/section';
import CardCta from './design/card-cta';
import { device, sizes } from '../styles/variables';

const { defaultSize } = sizes;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rem('20px', defaultSize)};

  @media ${device.m} {
    grid-template-columns: 1fr 1fr;
  }
`;

function Bounties({ comicTheme = false }) {
  const bounties = [
    {
      title: ['Recommend venues'],
      description:
        'Let our community know about the venues that impressed you with their taste in arts, especially with music.',
      ctaValue: 'Community suggestions',
      ctaUrl: 'https://app.dework.xyz/statefulart/places/community',
    },
    {
      title: 'Bring your own venue',
      description: [
        // 'Begin by acquiring a governance token and taking part in the platform\'s strategy by becoming a member of Decentralized Artistic Organicities.',
      // 'Start participating in Decentralized Artistic Organicities by acquiring a governance token and gaining a stake in the platform\'s strategy.',
    // 'Create a community for your venue and join Decentralized Artistic Organicities to gain a stake in the platform\'s strategy with our governance token $START.'  
    'Create a web3 community for your places and join Decentralized Artistic Organicities & soon have a stake in the platform\'s strategy with our governance token $START.'
  ],
      ctaValue: 'Visit stateful.art',
      ctaUrl: 'https://stateful.art',
    },
  ];

  return (
    <Section color={comicTheme ? 'blue' : 'purple'}>
      <Grid>
        {bounties &&
          Array.isArray(bounties) &&
          bounties.map((item, index) => (
            <CardCta
              key={index}
              title={item.title}
              description={item.description}
              ctaValue={item.ctaValue}
              ctaUrl={item.ctaUrl}
            />
          ))}
      </Grid>
    </Section>
  );
}

export default Bounties;
